import React from "react"
import { graphql, PageProps, Link } from "gatsby"
import { Button } from "../components/ui"
import { ArrowRight, ArrowLeft, AlignCenter } from "react-feather"
import Layout from "../components/layout"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { ContactQuery } from "./__generated__/ContactQuery"
import Error from "../components/error"
import Helmet from "react-helmet"

export default ({ data, location }: PageProps<ContactQuery>) => {
    const api_url = data.site.siteMetadata.contact.api_url
    const hasContactForm = api_url
    const contact = data.site.siteMetadata.contact
    return (
        <Layout
            seo={{
                title: "Sorry",
            }}
            location={location}
        >
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="container mx-auto h-screen py-12 px-12 content-center">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-1">Sorry</h2>
                </div>
                <Error />
                <div className="text-center mt-5">
                    申し訳ありません、エラーが発生しました。
                    <br />
                    誠にお手数ですが、下記連絡先よりご連絡ください。
                </div>
                <div className="items-center  text-center">
                    <ul className="my-4 flex flex-col justify-items-center">
                        {contact.mail && (
                            <li className="flex text-center justify-center mt-10">
                                <span className="text-secondary icon ">
                                    <Mail />
                                </span>
                                <a
                                    className="ml-4"
                                    href={"mailto:" + contact.mail}
                                >
                                    {contact.mail}
                                </a>
                            </li>
                        )}
                        {contact.phone && (
                            <li className="flex text-center justify-center mt-10">
                                <span className="text-secondary icon">
                                    <Phone />
                                </span>
                                <a
                                    className="ml-4"
                                    href={"tel:" + contact.phone}
                                >
                                    {contact.phone}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="m-6 text-center">
                    <Link to="/">
                        <Button
                            title="ホームへ戻る"
                            type="button"
                            iconLeft={<ArrowLeft />}
                            // iconRight={<ArrowRight />}
                        />
                    </Link>
                </div>

                {/* <div className="flex flex-wrap pb-40">
                    {hasContactForm && (
                        <>
                            <div className="w-full mx-auto justify-center items-center">
                                <Form api={api_url} color="pink" />
                            </div>
                        </>
                    )}

                    <div
                        className={`w-full ${
                            hasContactForm ? "lg:w-1/2" : "lg:w-2/3 mx-auto"
                        } px-6 pt-8`}
                    >
                    <Description data={data.site.siteMetadata.contact} />
                
                    </div>
                </div> */}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ContactQuery2 {
        site {
            siteMetadata {
                contact {
                    api_url
                    description
                    mail
                    phone
                    address
                }
            }
        }
    }
`
